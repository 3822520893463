.game-ui-root, .game-background, .game-animated-background, .stars-background {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.game-ui-root {
  z-index: 200;
}

.game-ui-top-root {
  width: 100%;
  height: 80px;
  color: #fff;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.game-ui-bottom-root {
  width: 100%;
  height: 128px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}

.game-ui-bottom-root .game-ui-bottom-content {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.game-ui-bottom-root .game-ui-bottom-content .back-button {
  width: 200px;
  pointer-events: all;
}

.game-ui-left-root {
  width: 384px;
  height: 100%;
  color: #fff;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.game-ui-right-root {
  width: 128px;
  height: 100%;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}

.game-ui-top-root, .game-ui-bottom-root, .game-ui-left-root, .game-ui-right-root {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.mandala-progress {
  width: 60px;
  height: 60px;
}

.mandala-progress img {
  width: 100%;
  height: 100%;
}

.mandala-progress-empty {
  width: 60px;
  height: 60px;
}

.mandala-container {
  background: #0003;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  display: flex;
}

.mute-ui {
  z-index: 300;
  position: absolute;
  top: 20px;
  left: 20px;
}

.mute-ui img {
  width: 40px;
  height: 40px;
}

.game-background {
  z-index: 0;
  background: linear-gradient(#545a7b 0%, #737796 16%, #8f7789 33%, #b0808c 50%, #c5868f 66%, #eaa7aa 83%, #f3cdcd 100%);
}

.stars-background {
  z-index: 1;
  background: url("stars.1bb1086b.png") 0 0 / 100%;
  animation: 800s linear infinite both infinite-stars;
}

@keyframes infinite-stars {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 10000px 20000px;
  }
}

.game-animated-background {
  z-index: 1;
  stroke-dasharray: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.game-animated-background object {
  width: 600px;
  max-width: 100%;
}

.logo-background object {
  margin-top: 40px;
  padding: 20px;
}

.background-invisible {
  opacity: 0;
}

.game-main-menu-root, .game-main-menu {
  z-index: 100;
  width: 100%;
  height: 100%;
}

.game-main-menu, .game-main-menu .main-menu-buttons {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 600px) {
  .game-main-menu .main-menu-buttons {
    flex-direction: row;
  }
}

.game-main-menu .main-menu-buttons img {
  width: 240px;
}

.game-main-menu .tutorial-container, .game-main-menu .credits-container {
  z-index: 300;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.game-main-menu .tutorial-container {
  color: #fff;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.game-main-menu .tutorial-container .tutorial-row {
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.game-main-menu .tutorial-container .tutorial-overlay {
  width: 80%;
  background: #fff3;
  border-radius: 30px;
  padding: 20px;
}

.game-main-menu .tutorial-container .tutorial-page {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

@media screen and (max-width: 600px) {
  .game-main-menu .tutorial-container .tutorial-page {
    flex-direction: column;
  }
}

@media screen and (min-width: 600px) {
  .game-main-menu .tutorial-container .tutorial-page {
    flex-direction: row;
  }
}

.game-main-menu .tutorial-container .tutorial-page-description {
  margin-right: 20px;
  display: inline-block;
}

@media screen and (max-width: 600px) {
  .game-main-menu .tutorial-container .tutorial-page-description {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .game-main-menu .tutorial-container .tutorial-page-description {
    width: 50%;
  }
}

.game-main-menu .tutorial-container .tutorial-page-description h1 {
  text-align: center;
  font-size: .8em;
}

.game-main-menu .tutorial-container .tutorial-page-description p {
  text-align: justify;
  font-size: .6em;
}

.game-main-menu .tutorial-container .tutorial-page-description .tutorial-mandala {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.game-main-menu .tutorial-container .tutorial-page-description img {
  width: 100px;
}

.game-main-menu .tutorial-container .tutorial-page-gif {
  display: inline-block;
}

@media screen and (max-width: 600px) {
  .game-main-menu .tutorial-container .tutorial-page-gif {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .game-main-menu .tutorial-container .tutorial-page-gif {
    width: 50%;
  }
}

.game-main-menu .tutorial-container .tutorial-page-gif .tutorial-gif {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.game-main-menu .tutorial-container .tutorial-page-gif img {
  width: 100%;
  border-radius: 20px;
}

.game-main-menu .tutorial-container .tutorial-page-button {
  width: 50px;
}

.game-main-menu .credits-container {
  color: #fff;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .6em;
  display: flex;
}

.game-main-menu .credits-container .credits-overlay {
  text-align: center;
  vertical-align: middle;
  background: #fff3;
  border-radius: 30px;
}

@media screen and (max-width: 600px) {
  .game-main-menu .credits-container .credits-overlay {
    width: 90%;
  }
}

@media screen and (min-width: 600px) {
  .game-main-menu .credits-container .credits-overlay {
    width: 50%;
  }
}

.game-main-menu .credits-container li {
  list-style-type: none;
}

.game-main-menu .credits-container li .credits-line {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
}

.game-main-menu .credits-container li .credits-line .credits-left {
  text-align: left;
}

.game-main-menu .credits-container li .credits-line .credits-right {
  text-align: right;
}

.game-over-root, .game-over-screen-root {
  z-index: 300;
  color: #fff;
  text-align: center;
}

.game-renderer-container {
  width: 100%;
  height: 100%;
  z-index: 150;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.game-renderer-container canvas {
  pointer-events: all;
}

@font-face {
  font-family: JohnMayerv2;
  src: url("JohnMayerv2.c3a27972.woff") format("woff");
}

body, html {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 30px;
  display: flex;
  overflow: hidden;
}

canvas {
  z-index: 100;
  background: none;
}

* {
  box-sizing: border-box;
  font-family: JohnMayerv2;
}

/*# sourceMappingURL=index.f96ff2ca.css.map */
