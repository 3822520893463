@font-face {
    font-family: 'JohnMayerv2';
    src: url('../../assets/fonts/JohnMayerv2.woff') format('woff');
}

@import url('./ui.scss');
@import url('./background.scss');
@import url('./main-menu.scss');
@import url('./game-over.scss');
@import url('./renderer.scss');

body,
html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    user-select: none;
    font-size: 30px;
}

canvas {
    z-index: 100;
    background: transparent;
}

* {
    box-sizing: border-box;
    font-family: 'JohnMayerv2';
}